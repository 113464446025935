import React, { useRef } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import { mobile } from "../../Responsive";

import { Customer } from "../../models/customer";
import { saveEmailToDB } from "../../utils/saveEmailsToDB";
import CustomSnackbar from "../../utils/displaySnackbar";
import { useDispatch } from "react-redux";
import {
  loginAction,
  registerAction,
} from "../../data/redux/actions/authAction";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  margin: auto;
  display: block;
`;

const ModContainer = styled.div`
  position: relative;
  background: white;
  width: 40%;
  z-index: 11;
  overflow: hidden;
  padding: 20px;
  border-radius: 12px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${mobile({ width: "80%" })};
`;

const Label = styled.div`
  font-size: 20px;
  cursor: none;
  padding: 10px;
`;

const Input = styled.input`
  min-width: 30%;
  flex: 1;
  background-color: white;
  border: 2px solid #4CAF50
  text-color: black;
  font-size: 18px;
  padding: 10px;
  margin: 20px 20px 0px 0px;
  ${mobile({ minWidth: "93%" })};
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Button = styled.button`
  background-color: black;
  border: none;
  width: 40%;
  color: white;
  border-radius: 5px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition-duration: 0.4s;
  &:hover {
    background-color: gray;
    color: white;
  }
  ${mobile({ fontSize: "12px", padding: "15px 10px", width: "50%" })};
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  margin-top: 25px;
  ${mobile({ fontSize: "15.5px", marginTop: "30px" })};
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  color: black;
  display: flex;
  float: right;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    color: gray;
  }
  ${mobile({ height: "25px", width: "25px" })};
`;

const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
  ${mobile({ padding: "5px", fontSize: "10px" })};
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Link = styled.a`
  font-size: 12px;
  margin: 10px 0px;
  width: 90%;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: gray;
  }
`;

const Modal = ({
  handleClose,
  show,
  isSignIn,
  children,
  isUserLoggedIn,
  displaySnackbar,
}) => {
  const dispatch = useDispatch();

  const emailRef = useRef(null);
  const passWordRef = useRef(null);

  const fullNameRef = useRef(null);
  const contactRef = useRef(null);

  const [isSigningUser, setSigninStatus] = React.useState(false);

  const performSignIn = async (e) => {
    e.preventDefault();

    if (emailRef.current.value === "") return;

    const email = emailRef.current.value;
    const password = passWordRef.current.value;

    setSigninStatus(true);
    const res = await dispatch(
      loginAction({ email: email, password: password })
    );
    setSigninStatus(false);

    if (res.payload.user === undefined) {
      if (res.payload) {
        displaySnackbar(res.payload);
        return;
      }
      displaySnackbar("An error occured, please try again");
      return;
    }

    displaySnackbar(res.payload.message);
    handleClose();
    isUserLoggedIn();
  };

  const performSignUp = async (e) => {
    e.preventDefault();
    if (emailRef.current.value === "") return;

    const email = emailRef.current.value;
    const password = passWordRef.current.value;

    const contact = contactRef.current.value;
    const fullName = fullNameRef.current.value;

    let firstName = "";
    let lastName = "";

    if (fullName !== "" && fullName.includes(" ")) {
      firstName = fullName.split(" ")[0];
      lastName = fullName.split(" ")[1];
    }

    setSigninStatus(true);
    const res = await dispatch(
      registerAction({
        email: email,
        password: password,
        contact: contact,
        firstName: firstName,
        lastName: lastName,
      })
    );
    setSigninStatus(false);

    if (res.payload.user === undefined) {
      displaySnackbar("An error occured, please try again");
      return;
    }

    displaySnackbar(res.payload.message);
    handleClose();
    isUserLoggedIn();
  };

  if (show) {
    if (isSignIn) {
      return (
        <Container>
          <ModContainer>
            <SocialIcon onClick={handleClose}>
              <CloseIcon />
            </SocialIcon>
            <Title> LOGIN </Title>
            <Form>
              <Input
                ref={emailRef}
                id="login-email"
                type="text"
                placeholder="Email"
              />
              <Input
                ref={passWordRef}
                id="login-password"
                type="password"
                placeholder="Password"
              />
              <Button onClick={performSignIn}>
                {isSigningUser ? (
                  <CircularProgress style={{ height: "30px", width: "30px" }} />
                ) : (
                  "SIGN IN"
                )}
              </Button>
              <Link> ALREADY HAVE AN ACCOUNT? </Link>
              <Link> FORGOT PASSWORD? </Link>
            </Form>
          </ModContainer>
        </Container>
      );
    } else {
      return (
        <Container>
          <ModContainer>
            <SocialIcon color="385999" onClick={handleClose}>
              <CloseIcon />
            </SocialIcon>
            <Title> CREATE AN ACCOUNT </Title>
            <Form>
              <Input
                ref={emailRef}
                id="register-email"
                type="text"
                placeholder="Email"
              />
              <Input
                ref={passWordRef}
                id="register-password"
                type="password"
                placeholder="Password"
              />
              <Input
                ref={fullNameRef}
                id="fullname"
                type="text"
                placeholder="Fullname"
              />
              <Input
                ref={contactRef}
                id="contact"
                type="number"
                placeholder="Mobile number"
              />
              <Agreement>
                By creating an account, I consent to the processing of my
                personal data in accordance with the <b> PRIVACY POLICY </b>
              </Agreement>
              <Button onClick={performSignUp}>
                {isSigningUser ? (
                  <CircularProgress style={{ height: "30px", width: "30px" }} />
                ) : (
                  "SIGN UP"
                )}
              </Button>
            </Form>
          </ModContainer>
        </Container>
      );
    }
  }
};

// ..................................................
// Dynamically create a paystack a Customer customer
// account for user directly after FirebaseAuth
// account creation to manage web payments
// ..................................................
async function createPaystackAccount(cred, fullname, contact, setOpenSnackbar) {
  // const config = require('../../utils/config');
  //   let firstName = fullname.split(' ')[0];
  //   let lastName = fullname.split(' ')[1];
  //   const params = JSON.stringify({
  //     email: cred.user.email,
  //     first_name: firstName,
  //     last_name: lastName,
  //     phone: contact,
  //   });
  //   const options = {
  //     port: 443,
  //     method: "POST",
  //     body: params,
  //     headers: {
  //       Authorization: "Bearer " + config.stackSecret,
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   const res = await fetch("https://api.paystack.co/customer", options);
  //   if (!res.ok) {
  //     throw new Error(`Request failed with status ${res.status}`)
  //   }
  //   if(res.status === 200){
  //     res.json().then((val) => {
  //     saveDataToFirebase(cred, val.data, setOpenSnackbar);
  //     })
  // }
}

export default Modal;

import React from 'react';
import { Parallax } from 'react-parallax';
import styled from 'styled-components';
import Fits from '../assets/fits.jpg';
import Logo from '../assets/agoro_logo.jpg';
import { mobile } from "../Responsive";
import { tablet } from "../Responsive";

import { useNavigate } from "react-router-dom";

const Container = styled.div`
    margin-bottom: 0rem;
    margin-top: 0rem;

    .image {
        position: relative;
        height: 50vh;
        ${mobile({ height: "40vh"})};
    }
    
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 50vh;
    width: 100%;
    ${mobile({ height: "40vh"})};
`;

const Text = styled.h2`
    color: white;
    letter-spacing: 5px;
    font-size: 1rem;
    margin: 1rem;
    ${mobile({ margin: "1rem 3.9rem", fontSize: "0.9rem" })};
`;

const Span = styled.span`
    background-color: black;
    color: white;
    padding: 1rem;
    font-size:1rem;
    letter-spacing: 10px;
    cursor: pointer;
`;

const CollectionButton = () => {
  let navigate = useNavigate();

  return (
    <Container>
    <Parallax className='image' bgImage={Logo} strength={800}>
        <Content>
            <Text> Explore through our various collections</Text>
            <Span onClick={()=> navigate("/collections")}> Shop Now </Span>
        </Content>
    </Parallax>
    </Container>
  )
}

export default CollectionButton
import { Facebook, Instagram, MailOutline, Phone, Pinterest, Room, Twitter } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import {mobile} from "../Responsive";
import { Link, useNavigate } from "react-router-dom";


const Container = styled.div`
display: flex;
${mobile({ flexDirection: "column"})};
`;

const Left = styled.div`
flex: 1;
display: flex;
flex-direction: column;
padding: 20px;
`;

const Logo = styled.h1`

`;

const Desc = styled.p`
margin: 20px 0px;
`;

const SocialContainer = styled.div`
display: flex;
`;

const SocialIcon = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
color: black;
align-items: center;
display: flex;
justify-content: center;
cursor: pointer;
margin-right: 20px;
`;

const Center = styled.div`
flex: 1;
padding: 20px;
${mobile({ display: "none"})};
`;

const Title = styled.h3`
margin-bottom: 30px;
`;

const List = styled.ul`
margin: 0;
padding: 0;
list-style: none;
display: flex;
flex-wrap: wrap;
`;

const ListItem = styled.li`
width: 50%;
margin-bottom: 10px;
cursor: pointer;
`;

const Right = styled.div`
flex: 1;
padding: 20px;
${mobile({ })};
`;

const ContactItem = styled.div`
margin-bottom: 20px;
display: flex;
align-items: center;
`;

const Payment = styled.img`
width: 50%;
`;


const Footer = () => {
    const navigate = useNavigate();

  return (
    <Container>
        <Left>
            <Logo> Byagoro </Logo>
            <Desc>
                Dreams to reality
            </Desc>
            <SocialContainer>
               <SocialIcon>
                   <Link to='/social'
                   target="_blank"
            rel="noopener noreferrer"
            style={{color: 'purple'}}>
                    <Instagram onClick={()=>{
                        localStorage.setItem("socialUrl", 'https://instagram.com/by.agoro_?igshid=YmMyMTA2M2Y=');
                    }} />
                    </Link>
                </SocialIcon>
                <SocialIcon>
                   <Link to='/social' 
                   target="_blank"
            rel="noopener noreferrer"
             style={{color: 'lightblue'}}>
                    <Twitter onClick={()=>{
                        localStorage.setItem("socialUrl", 'https://twitter.com/by_agoro?s=21&t=s5ekieIrOhCaaanXb3GhAA');
                    }} />
                    </Link>
                </SocialIcon>
            </SocialContainer>
        </Left>
        <Center>
            <Title> Useful Links </Title>
            <List>
                <ListItem onClick={() => navigate('/')}> Home </ListItem>
                <ListItem onClick={() => navigate('/cart')}> Cart </ListItem>
                <ListItem onClick={() => navigate('/profile')}> My Account </ListItem>
                <ListItem onClick={() => navigate('/wishlist')}> Wishlist </ListItem>
                <ListItem onClick={() => navigate('/order/open')}> Orders </ListItem>
                <ListItem> Terms </ListItem>
            </List>
        </Center>
        <Right>
            <Title> Contact </Title>
            <ContactItem>
                <Room style={{marginRight: "10px"}} />
                Lagos State, Nigeria.
            </ContactItem>
            <ContactItem>
                <MailOutline style={{marginRight: "10px"}}/>
                hello@byagoro.com
            </ContactItem>
            <Payment src="https://i.ibb.co/Qfvn4z6/payment.png"/>
            <ContactItem>
            <div style={{marginTop: "8rem"}} />
            &copy; 2024 Byagoro. All Rights Reserved
            </ContactItem>
        </Right>
    </Container>
  )
}

export default Footer